import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { useAlternateLangs } from "../../components/Hreflangs";
import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
  Hjem: "/",
  // Blogg: "/blogg",
  "Juridisk Merknad": "/no/juridisk-merknad"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/legal-notice"
);

const LegalNotice = props => {
  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Juridiske Merknader"
          description="Juridiske merknader om Matthias Kupperschmidts tjenester innen online marketing; dette inkluderer søkemotoroptimalisering, implementeringer av webanalyse, rådgivning innen online marketing"
          lang="no"
          alternateLangs={alternateLangs}
        />
        <MainContent
          article={false}

        >
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

          <H as="h1">Juridisk Merknad</H>

          <p>Matthias Kupperschmidt tilbyr ulike tjenester (omtalt som "tjenester" nedenfor) innen online marketing; dette inkluderer søkemotoroptimalisering, implementeringer av webanalyse, rådgivning innen online marketing samt kurs og workshops.</p>

          <p>Bruken av de leverte tjenestene er kun tillatt for det formålet som er beskrevet i disse Generelle Vilkårene og/eller bestillingen. I tillegg er enhver bruk eller behandling for andre formål kun tillatt under strenge betingelser i henhold til dansk personvernlovgivning, spesielt Personopplysningsloven og EUs generelle personvernforordning (GDPR). Enhver urettmessig bruk eller utnyttelse er ikke tillatt. Spesielt er full bruk av de leverte tjenestene og innholdet til kommersielle formål av noe slag og i noe medieformat (trykt, elektronisk, på CD, etc.) samt utlesing av kataloger, databaser, etc., for de ovennevnte formålene eller for andre kommersielle formål ikke tillatt og vil bli forfulgt av leverandøren i henhold til gjeldende lov ved å utnytte alle tilgjengelige juridiske midler.</p>
          <p>Tilgjengeligheten av individuelle tjenester samt riktigheten og fullstendigheten av innholdet/oppføringene/dataene garanteres ikke.</p>
          <p>Disse Vilkårene regulerer forholdet mellom Matthias Kupperschmidt og hans kunder og kontraktsparter (omtalt som "kontraktsparter" nedenfor)</p>

          <p>Seksjon 1 Omfang</p>
          <p>Tjenestene til Matthias Kupperschmidt utføres utelukkende på grunnlag av disse Generelle Vilkårene. De gjelder derfor også for alle fremtidige forretningsforhold, selv om de ikke uttrykkelig avtales igjen.</p>
          <p>Bruken av tjenestene som tilbys av Matthias Kupperschmidt til kontraktsparter styres utelukkende av disse Generelle Vilkårene (GTV) samt spesialvilkårene for de respektive produktene/tjenestene. For tilbud, tjenester, individuelle prosjekter, kampanjer og andre tjenester, spesielt prosjektbaserte tjenester utover det, gjelder vilkårene som skal avtales i separate avtaler.</p>
          <p>Generelle vilkår fra kontraktsparter eller andre tredjeparter gjelder ikke selv om Matthias Kupperschmidt ikke uttrykkelig motsetter seg dem og/eller leverer sine tjenester uten innvending.</p>

          <p>Seksjon 2 Generelt omfang av tjenester</p>
          <p>Blant annet er Matthias Kupperschmidt aktiv innen søkemotoroptimalisering, webanalyse, inbound marketing, rådgivning innen online marketing samt kurs og workshops. Den detaljerte beskrivelsen av tjenesten som bestilles i hvert tilfelle kan sees i den respektive bestillingen.</p>
          <p>Tjenestene og innholdet som tilbys av Matthias Kupperschmidt leveres og publiseres av Matthias Kupperschmidt med mindre annet er spesifisert. Enhver informasjon som gjøres tilgjengelig av tredjeparter er informasjon levert av den respektive skaperen, forfatteren eller distributøren og ikke informasjon levert av Matthias Kupperschmidt. Matthias Kupperschmidt er derfor ikke ansvarlig for nøyaktigheten eller påliteligheten av dette innholdet.</p>
          <p>Tjenestene som tilbys av Matthias Kupperschmidt er kun en invitasjon til å levere et forslag om å inngå en kontrakt. Kontraktsparter er bundet av sin erklæring i ti arbeidsdager. Bekreftelse i tekstform (e-post er tilstrekkelig) av Matthias Kupperschmidt kreves for inngåelse av en kontrakt. En forretningskontrakt inngås deretter på grunnlag av de gjeldende GTV.</p>
          <p>Matthias Kupperschmidt er ikke forpliktet til å inngå kontrakter. Han har rett til å nekte inngåelse av kontrakter uten å oppgi grunner. Etterfølgende endringer og tillegg til kontraktene krever skriftlig bekreftelse fra Matthias Kupperschmidt. Separate avtaler skal inngås av kontraktsparter for ytterligere tjenester.</p>
          <p>Matthias Kupperschmidt forbeholder seg også retten til å utelukke kontraktsparter på et senere tidspunkt fra bruken av tjenestene dersom det er bevis for at kontraktsparten misbruker tjenestene eller bruker dem på en måte som kan sette omdømmet til Matthias Kupperschmidt i fare. Dette gjelder spesielt hvis kontraktsparten bryter plikter fra kontraktsforholdet eller skader andre kontraktsparter av Matthias Kupperschmidt.</p>
          <p>I den grad en kontraktspart bruker programvare til den respektive andre kontraktsparten som en del av bruken av tjenestene, gir den respektive andre kontraktsparten ham – med mindre annet er uttrykkelig regulert – en ikke-eksklusiv, ikke-overførbar bruksrett, for hans egen bruk, som er tidsbegrenset til kontraktens varighet og, i innhold, begrenset til bruk innenfor rammen av leveransen av tjenesten. Ytterligere bruksrettigheter gis ikke til kontraktsparten.</p>
          <p>For vedlikeholdsformål og for å utføre reparasjoner, har Matthias Kupperschmidt rett til å avbryte leveringen av tjenester eller deler av dem i den grad det er rimelig for kontraktsparten. Generelt skal Matthias Kupperschmidt forsøke å utføre vedlikeholds- og reparasjonsarbeid på tidspunkter der den belastende virkningen av det vil være lavest mulig. Dette gir ikke grunnlag for krav om erstatning eller kompensasjon fra kontraktsparten med mindre Matthias Kupperschmidt er skyld i det.</p>
          <p>Matthias Kupperschmidt har rett til å inngå lignende kontraktsforhold med konkurrenter av kontraktsparten. Matthias Kupperschmidt skal imidlertid forsøke – uten å være forpliktet til det – å beskytte de rettferdige interessene til kontraktsparten i denne forbindelse og unngå interessekonflikter. Matthias Kupperschmidt har rett til å levere delvis tjenester samt tjenester fra tredjeparter.</p>
          <p>Frister og datoer som kontraktsparten ber om er bare bindende når Matthias Kupperschmidt uttrykkelig har bekreftet dem skriftlig, og hvis kontraktsparten har opprettet i tide alle nødvendige betingelser for leveransen av tjenesten som er innenfor hans innflytelsessfære.</p>
          <p>I den grad og så lenge kontraktsparten ikke har oppfylt noen av sine samarbeidsplikter som kreves for leveransen av tjenesten, selv etter en skriftlig anmodning fra Matthias Kupperschmidt og fastsettelse av en rimelig frist, skal Matthias Kupperschmidt være fritatt fra å oppfylle den forpliktelsen som er påvirket av denne unnlatelsen ved den avtalte fristen i prosjektkontrakten. Fristen som er avtalt i bestillingen/kontrakten for leveransen skal forlenges tilsvarende.</p>


          <p>Seksjon 3 Inngåelse av kontrakt, kontraktens omfang</p>
          <p>Hver tjeneste må bestilles separat av kontraktsparten (individuell bestilling/tjenestenivåavtale). Kontraktsparter skal gjensidig gi hverandre det innholdet som kreves for å oppfylle bestillingen. Hvis sporingskoder er integrert i dette innholdet, kan de bare endres eller slettes av den respektive andre kontraktsparten etter forhåndsavtale og enighet.</p>
          <p>Matthias Kupperschmidt skal opprette en faktura for kontraktsparten for hver gjennomførte kampanje som tydelig viser all informasjon spesifisert i punkt 1.</p>

          <p>Seksjon 4 Plikter til kontraktsparten</p>
          <p>Klienten forplikter seg til å støtte tiltakene som Matthias Kupperschmidt har tatt. Klienten skal gi Matthias Kupperschmidt all informasjon og data som kreves for å oppfylle bestillingen.</p>
          <p>Kontraktsparten forplikter seg til å sikre at innholdet han leverer og som er knyttet til bruken av tjenestene til Matthias Kupperschmidt ikke bryter gjeldende lov i Danmark; dette gjelder spesielt ulovlig, umoralsk eller pornografisk innhold eller slikt innhold som glorifiserer krig eller er egnet til å utgjøre en moralsk risiko for barn og unge eller påvirke deres velvære negativt.</p>
          <p>Kontraktsparten skal også rapportere alle hendelser og omstendigheter som kan være av betydning for korrekt og rettidig gjennomføring av kontrakten. Dette gjelder også hendelser og omstendigheter som kontraktsparten først blir kjent med i løpet av kontraktens varighet. Prosjektforsinkelser på grunn av at kontraktsparten ikke oppfyller nødvendige samarbeidsplikter eller ikke oppfyller dem i tide er på kontraktspartens bekostning og påvirker ikke Matthias Kupperschmidts krav på godtgjørelse.</p>
          <p>Enhver brudd på forpliktelsen spesifisert i punkt 1 gir Matthias Kupperschmidt rett til å si opp kontrakten mellom Matthias Kupperschmidt og kontraktsparten umiddelbart. Matthias Kupperschmidt forbeholder seg retten til umiddelbar oppsigelse selv om det bare er mistanke om at kontraktsparten sprer eller tolererer innhold som beskrevet i punkt 1. Matthias Kupperschmidt har rett, men ikke plikt, til å overvåke lovligheten av innholdet.</p>
          <p>Kontraktsparten forplikter seg til å overholde de tekniske og innholdsmessige spesifikasjonene som Matthias Kupperschmidt krever. Eventuelle forsinkelser og/eller endringer angående tjenestene levert av Matthias Kupperschmidt som skyldes manglende overholdelse av disse spesifikasjonene er på kontraktspartens bekostning og gir ikke grunnlag for krav mot Matthias Kupperschmidt.</p>
          <p>I tillegg er kontraktsparten ansvarlig for at all informasjon og dokumenter som kreves for oppfyllelsen av hans kontraktsforpliktelser og korrekt gjennomføring av samarbeidet gjøres tilgjengelig for Matthias Kupperschmidt i tide, komplett og i en form som er egnet for oppfyllelse før den avtalte gjennomføringen av kontrakten.</p>
          <p>Hvis en part hindres fra å levere den kontraktsmessige tjenesten og/eller innholdet på grunn av omstendigheter som den ikke er ansvarlig for, skal denne parten informere den respektive andre parten tilsvarende og umiddelbart overføre tilpasset innhold og/eller tjenester. Hvis de ovennevnte omstendighetene varer mer enn 24 timer, skal partene bli enige om hvordan de skal gå videre.</p>
          <p>Kontraktsparten har ikke rett til å bruke, reprodusere, distribuere, publisere, endre eller utnytte på annen måte merkene og/eller logoene til Matthias Kupperschmidt, hans kunder og partnere med mindre Matthias Kupperschmidt uttrykkelig har gitt skriftlig samtykke på forhånd. Videre er kontraktsparten forpliktet til å respektere tredjeparts opphavsrettigheter eller varemerkerettigheter, både danske og utenlandske.</p>
          <p>Kontraktsparten samtykker til videreformidling av hans innhold, data og annen informasjon til tredjeparter i den grad det er nødvendig og kreves for oppfyllelsen av kontraktsforholdet mellom partene.</p>

          <p>Seksjon 5 Kontraktens varighet/oppsigelse</p>
          <p>Kostnadsestimater fra Matthias Kupperschmidt er ikke bindende. Matthias Kupperschmidt forplikter seg til å varsle kontraktsparten umiddelbart om eventuelle avvik i de faktiske kostnadene sammenlignet med estimatet hvis avviket er mer enn ti prosent.</p>
          <p>Kontrakten inngått mellom Matthias Kupperschmidt og kontraktsparten avsluttes ved utløpet av den avtalte varigheten. For øvrig kan den respektive kontraktsvarigheten ses fra tilbudet.</p>
          <p>Hvis en minimumsperiode er avtalt med kontraktsparten, forlenges avtalen automatisk med den avtalte perioden med mindre forlengelsen blir motarbeidet senest 30 dager før utløpet av den aktuelle kontraktsperioden.</p>
          <p>Kontrakter som er inngått for en ubestemt periode kan sies opp av begge parter skriftlig når som helst med en oppsigelsesfrist på tre måneder til slutten av måneden.</p>
          <p>Retten til å si opp kontrakten av en god grunn forblir upåvirket. En god grunn er spesielt gitt i tilfelle en av partene bryter essensielle plikter og forpliktelser som oppstår fra kontrakten eller gjeldende lov i Danmark og ikke gjenoppretter den kontraktsmessige tilstanden til tross for en skriftlig advarsel fra den andre parten innen 7 dager etter mottak av advarselen. Advarselen må spesifisere brudd på en forpliktelse eller plikt og påpeke mulighetene for oppsigelse av en god grunn. Essensielle kontraktsforpliktelser er spesielt forpliktelser til å opprettholde konfidensialitet.</p>

          <p>For Matthias Kupperschmidt er en god grunn gitt hvis</p>
          <p>åpningen av insolvens- eller konkursbehandling mot eiendelene til kontraktsparten er innlevert</p>
          <ol>
            <li>enhver annen skyldig brudd på essensielle kontraktsforpliktelser eller plikter av en kontraktspart hvis de ikke er blitt umiddelbart eliminert til tross for en advarsel.</li>
          </ol>
          <p>Frem til oppsigelsen trer i kraft, beholder hver kontraktspart sitt krav på eventuelle avgifter og provisjoner som fortsatt vil påløpe. Frem til dette tidspunktet er kontraktspartene også forpliktet til å oppfylle sine kontraktsforpliktelser. Enhver forpliktelse som går utover kontraktsperioden, spesielt de som gjelder databeskyttelse og konfidensialitet, forblir upåvirket av dette.</p>
          <p>Alle oppsigelser må gjøres skriftlig eller via e-post.</p>

          <p>Seksjon 6 Godtgjørelse og forfallsdatoer</p>
          <p>Den avtalte godtgjørelsen forfaller ved slutten av rådgivningen og/eller gjennomføringen av tiltakene. En aksept er ikke nødvendig.</p>
          <p>Matthias Kupperschmidt har rett til tilstrekkelig betaling allerede før slutten av rådgivningen og gjennomføringen av tiltakene. I tvilstilfeller er tilstrekkeligheten basert på den allerede leverte arbeidstiden. I tilfelle av permanent rådgivning, har Matthias Kupperschmidt rett til å fakturere tjenester umiddelbart etter at de er levert. Godtgjørelsen er basert på tilbudet fra Matthias Kupperschmidt.</p>

          <p>Seksjon 7 Pengeroverføring, betalingsbetingelser</p>
          <p>Godtgjørelsen, eller fordelingen av salgsinntekter, skjer alltid i henhold til fordelingen spesifisert i den individuelle bestillingen.</p>
          <p>Med mindre annet er avtalt i den individuelle bestillingen, skjer oppgjøret månedlig av Matthias Kupperschmidt. Alle salgstall og avgifter er nettobeløp; merverdiavgift vises separat.</p>
          <p>Matthias Kupperschmidt har rett til å kreve forskuddsbetaling på opptil 100% av bestillingsvolumet; forskuddsbetalinger forfaller ved signering av kontrakten.</p>
          <p>I tilfelle forsinket betaling har Matthias Kupperschmidt rett til å kreve forsinkelsesrenter fra kontraktsparten – samtidig som han forbeholder seg retten til å gjøre gjeldende ytterligere skadeerstatning – på fem prosentpoeng over den respektive basisrenten. Forsinkelsesrenten er lavere hvis kontraktsparten beviser en lavere skade.</p>
          <p>Kontraktsparten har kun en rett til tilbakeholdelse eller motregning i den grad kravene fra kontraktsparten er ubestridte og er rettslig fastsatt.</p>


          <p>Seksjon 8 Eiendomsrett og opphavsrettsbeskyttelse, bruksrettigheter</p>
          <p>Opphavsrettigheter og andre eiendomsrettigheter til tjenestene levert av Matthias Kupperschmidt forblir hos Matthias Kupperschmidt.</p>
          <p>Matthias Kupperschmidt gir kontraktsparten ikke-eksklusive, midlertidige og territorielt ubegrensede bruksrettigheter til utnyttelse av den fullstendige tjenesten som er levert i henhold til kontrakten (arbeidsresultater).</p>
          <p>Kontraktsparten har imidlertid ikke rett til å selge det leverte innholdet og informasjonen til tredjeparter eller gjøre dem tilgjengelige for tredjeparter utenfor deres tiltenkte formål.</p>
          <p>Endringer av tjenestene levert av Matthias Kupperschmidt som skal utføres av kontraktsparten eller en tredjepart som er oppnevnt av kontraktsparten er kun tillatt med uttrykkelig samtykke fra Matthias Kupperschmidt.</p>
          <p>Bruken av bruksrettighetene er kun tillatt dersom kontraktsparten ikke er i betalingsmislighold på det tidspunktet.</p>
          <p>På grunnlag av avtaler med ansatte, agenter og eventuelle tredjeparters rettighetshavere, sørger Matthias Kupperschmidt for at kontraktsparten kan fritt utøve de kontraktsmessige bruksrettighetene.</p>

          <p>Seksjon 9 Garanti og ansvar</p>
          <p>Matthias Kupperschmidt skal drive sine tjenester innenfor rammene av sine tekniske muligheter og påtar seg ingen garanti eller garanti for uavbrutt og/eller konstant feilfri tilgjengelighet av sine tjenester; spesielt ikke for linjeutbrudd og/eller forbindelsesfeil, maskinvare- og/eller programvarefeil eller for handlinger fra tredjeparter (f.eks. virus eller "denial of service" angrep). Kontraktsparter er enige om at forekomsten av slike avbrudd og/eller feil ikke gir grunnlag for krav om erstatning eller kompensasjon av noe slag.</p>
          <p>Feil er avvik fra tjenestebeskrivelsen som begrenser den kontraktsmessige bruken. Eventuelle feil må rapporteres til Matthias Kupperschmidt i detalj. Partene skal samarbeide i feilanalysen og feilsøkingen. Rettelser utføres innenfor rammen av ansvaret for feil og innebærer ingen kostnader for kontraktsparten.</p>
          <p>Kontraktsparten skal gi Matthias Kupperschmidt en tilstrekkelig periode for rettelser. Hvis rettelsene ikke lykkes selv innen en annen, tilstrekkelig frist (minst, men innen 15 arbeidsdager), har kontraktsparten rett til å kreve en reduksjon i betalingen eller erstatning for skade på grunn av manglende oppfyllelse hvis han har varslet om et slikt skritt når han satte fristen.</p>
          <p>Krav om feil fra kontraktsparten kan ikke lenger gjøres gjeldende hvis han har utført endringer i tjenestene selv eller har fått slike endringer utført av tredjeparter uten forhånds skriftlig samtykke, med mindre kontraktsparten kan bevise at feilene ikke skyldes disse endringene eller at disse endringene ikke hindrer feilanalysen og elimineringen eller gjør det kun marginalt.</p>
          <p>Krav om feil er i prinsippet utelukket hvis kontraktsparten bruker tjenestene under bruksbetingelser som avviker fra de spesifiserte i avtalen. Det er kontraktspartens ansvar å bevise at feilen ikke ble forårsaket av en slik avvik.</p>
          <p>Krav er utelukket for skade under den danske kjøpsloven på grunn av mangler ved kontraktens begynnelse som Matthias Kupperschmidt ikke er ansvarlig for, samt på grunn av bare uaktsomme mangler.</p>
          <p>Krav om feil utløper ett år etter aksept; i tilfelle tjenester som ikke kan eller ikke trenger aksept, ett år etter levering/overtakelse eller etter melding om fullføring og/eller levering av tjenesten.</p>
          <p>Matthias Kupperschmidt er ikke ansvarlig for tekniske feil hvis årsakene ikke ligger innenfor Matthias Kupperschmidts ansvarsområde eller for skade forårsaket av force majeure. I eksepsjonelle tilfeller kan det oppstå at enkelte data, tjenester eller andre tekniske eller faktiske aktiviteter ikke kan fanges opp eller faktureres korrekt av Matthias Kupperschmidt. Ethvert krav basert på dette – spesielt krav om skade eller verdierstatning – mot Matthias Kupperschmidt fra kontraktsparten er utelukket.</p>
          <p>Matthias Kupperschmidt er kun ansvarlig for skade på grunn av brudd på vesentlige kontraktsforpliktelser eller på grunn av fravær av sikre funksjoner og egenskaper. Utover det er Matthias Kupperschmidt kun ansvarlig hvis skaden ble forårsaket på grunn av grov uaktsomhet eller forsettelig hensikt.</p>
          <p>Matthias Kupperschmidt er ansvarlig ved en lett uaktsom brudd på en vesentlig kontraktsforpliktelse og, i tilfelle av ikke-kontraktsansvar, kun i tilfelle tap av liv, fysisk skade og skade på helse, i tilfelle av brudd på garantier, vesentlige kontraktsforpliktelser samt i samsvar med produktansvarsloven kun i det omfang som gjelder for typisk skade som var forutsigbar da kontrakten ble inngått. Skaden beregnes på grunnlag av det gjennomsnittlige månedlige bestillingsvolumet innen de siste seks månedene. Spesielt er ansvar for lett uaktsom brudd på vesentlige kontraktsforpliktelser i tilfelle tap av fortjeneste, indirekte skade og følgeskader utelukket i prinsippet.</p>
          <p>Ansvar for følgeskader, indirekte skade og/eller tap av fortjeneste er utelukket med mindre i tilfelle av forsettelig oppførsel. I beløp er ansvar begrenset til forventet normal skade i tilfelle tap innen Danmark; maksimalt til den totale godtgjørelsen som skal betales av kontraktsparten i henhold til den respektive bestillingen. Denne ansvarsbegrensningen gjelder på samme måte for skade forårsaket av grov uaktsomhet eller forsett på vegne av ansatte eller agenter av Matthias Kupperschmidt.</p>
          <p>I tilfelle kontraktsparten har gjort endringer i den kontraktsmessige tjenesten eller dens miljø eller har fått slike endringer utført av tredjeparter, utløper alle krav fra kontraktsparten fra de ovennevnte bestemmelsene, med mindre feilen ikke har blitt forårsaket, helt eller delvis, av de utførte endringene av kontraktsparten og rettelsen ikke gjøres vanskeligere av endringene.</p>
          <p>Disse ansvarsbegrensningene gjelder mutatis mutandis for alle ansatte og agenter av Matthias Kupperschmidt. Ethvert ansvar av Matthias Kupperschmidt på grunn av produktansvarsloven forblir upåvirket.</p>
          <p>Alle krav om skade eller andre krav som kan fremsettes mot Matthias Kupperschmidt på grunn av egenskaper og tilstand eller mangler på tjenesten faller under foreldelse 6 (i ord: seks) måneder etter slutten av den respektive kontrakten.</p>
          <p>Kontraktsparten er ansvarlig overfor Matthias Kupperschmidt for innholdet, dokumentene, lenkene og annet materiale (omtalt som "dokumenter" nedenfor) levert av ham og gjort tilgjengelig for Matthias Kupperschmidt for gjennomføringen av samarbeidsprosjektet.</p>
          <p>Hvis nødvendig, skal kontraktsparten på egen regning sjekke om tjenestene levert av Matthias Kupperschmidt overholder relevante lovbestemmelser. Matthias Kupperschmidt vil initiere en slik ekstern juridisk sjekk kun på uttrykkelig skriftlig forespørsel fra kontraktsparten; de tilknyttede kostnadene skal bæres av kontraktsparten.</p>
          <p>I tilfelle tredjeparter fremsetter krav mot Matthias Kupperschmidt på grunn av brudd på opphavsrettigheter og/eller brudd på relevante lovbestemmelser som oppstod innenfor ansvarsområdet til kontraktsparten, skal kontraktsparten gjøre alt i sin makt for å forsvare Matthias Kupperschmidt mot de fremsette kravene eller rettighetene og eliminere den påståtte krenkelsen. Kontraktsparten er også ansvarlig overfor Matthias Kupperschmidt for eventuelle skader forårsaket av tredjeparter som oppsto som følge av at bestillingen ble utført delvis, feilaktig, forsinket, sent eller ikke i det hele tatt. Det samme gjelder eventuelle agenter av kontraktsparten. Kontraktsparten skal bære bevisbyrden for riktig utførelse av bestillingen.</p>
          <p>Kontraktsparten frigjør Matthias Kupperschmidt fra alle krav om skade, ansvarsansvar og kostnader som Matthias Kupperschmidt pådrar seg på grunn av at det fremsettes krav mot ham i henhold til at dokumentene til kontraktsparten bryter konkurranselov, krenker tredjeparts industrielle eiendomsrettigheter eller andre lover og forskrifter. Spesielt skal kontraktsparten frigjøre Matthias Kupperschmidt, på første forespørsel, umiddelbart fra alle krav fra tredjeparter fremsatt mot Matthias Kupperschmidt på grunn av innholdet brukt eller et tilbud lenket med hyperkobling, spesielt under den danske personopplysningsloven (DDPA) og EUs generelle personvernforordning (GDPR) og skal tilbakebetale, etter forespørsel, alle kostnader Matthias Kupperschmidt pådrar seg på grunn av passende rettslige tiltak.</p>

          <p>Seksjon 10 Tillatelser for nettsteder</p>
          <p>Matthias Kupperschmidt kontrollerer ikke om innholdet på kundens nettsteder krenker rettighetene til tredjeparter eller er satt opp i samsvar med retningslinjene til de enkelte søkemotoroperatørene. Kunden alene er ansvarlig for eventuelle straff fra tredjeparter.</p>
          <p>Kunden er også ansvarlig for den juridiske tillatelser for innholdet på hans nettsteder samt for informasjonen levert av ham, inkludert søkeordene eller nøkkelordene; det samme gjelder beskyttelsen av rettighetene til tredjeparter, spesielt med hensyn til opphavsrett, konkurranselov og strafferett.</p>

          <p>Seksjon 11 Tjenestekontrakt</p>
          <p>Ansatte ansatt innenfor rammene av tjenestekontrakter er ikke tillatt å bli rekruttert av kunden innen en periode på 24 måneder etter kontraktstildelingen. Hvis en slik rekruttering skjer innen denne perioden, har entreprenøren rett til en engangsbetaling på €75,000.</p>

          <p>Seksjon 12 Databeskyttelse</p>
          <p>Uten en ekstra samtykkeerklæring, samles, behandles og brukes personopplysninger – med mindre annet er spesifisert – kun for formålet med å levere tjenestene, bruken av tjenestene og, hvis aktuelt, for fakturering.</p>
          <p>Kontraktsparten er forpliktet til å overholde alle gjeldende bestemmelser om databeskyttelse, spesielt bestemmelsene i den danske personopplysningsloven (DDPA) og EUs generelle personvernforordning (GDPR).</p>
          <p>Kontraktsparten garanterer å bruke data han kan ha samlet utelukkende for det angitte formålet samt for markedsundersøkelse og reklameformål innen selskapet, i den grad det er nødvendig for gjennomføringen av de inngåtte transaksjonene og opprettholdelsen av det resulterende brukerforholdet og hvis det er lovlig og ønsket av brukeren. Videreformidling av data til tredjeparter fra kontraktsparten er utelukket, og anklager vil bli fremmet.</p>

          <p>Seksjon 13 Konfidensialitet og taushetsplikt</p>
          <p>Kontraktsparten er forpliktet til å bruke dokumentene, kunnskapen og erfaringen oppnådd fra Matthias Kupperschmidt utelukkende for formålene med denne kontrakten. I tillegg er partene enige om konfidensialitet med hensyn til tredjeparter om innholdet i avtalene inngått samt all kunnskap oppnådd under deres implementering. Kontraktsparten er forpliktet til å rådføre seg med Matthias Kupperschmidt dersom det oppstår tvil om hvorvidt informasjon i et spesifikt individuelt tilfelle skal behandles konfidensielt.</p>
          <p>Ved en mulig underentreprise skal kontraktsparten forplikte den respektive underentreprenøren til forpliktelser innenfor meningen med denne bestemmelsen.</p>
          <p>Kontraktsparten skal sikre overholdelse av denne konfidensialitetsforpliktelsen med hensyn til sine ansatte og eventuelle tredjeparter involvert i gjennomføringen av kontrakten som har tilgang til dokumentene og objektene spesifisert i første avsnitt.</p>


          <p>Seksjon 14 Endringer</p>
          <p>Matthias Kupperschmidt skal levere sine tjenester i samsvar med de respektive produktbeskrivelsene. Matthias Kupperschmidt forbeholder seg retten til å endre, utvide eller tilpasse tjenestene, som helhet eller delvis, eller å endre, utvide eller tilpasse typen og innholdet av individuelle produkter og tjenester som tilbys innenfor rammen av tjenesteleveransen når som helst og gjentatte ganger for å forbedre tilbudet, spesielt funksjonene, designet og teknisk utvikling. Kontraktsparten skal informeres om store endringer i produktene via e-post i god tid. Den nye versjonen av produktbeskrivelsene skal vedlegges. Hvis kontraktsparten ikke er enig i endringen, har han rett til å si opp kontrakten med Matthias Kupperschmidt med umiddelbar virkning. Hvis kontraktsparten ikke motsetter seg endringen innen to uker, anses de varslede endringene som de nye vilkårene.</p>
          <p>I tillegg har Matthias Kupperschmidt rett til å avvikle individuelle funksjoner som tilbys som en del av tjenestene – spesielt i tilfelle endrede lovbestemmelser, langvarige tap, tekniske vanskeligheter eller ved misbruk fra kunder, kontraktsparter eller tredjeparter.</p>
          <p>Matthias Kupperschmidt forbeholder seg også uttrykkelig retten til å gjøre prisendringer, som Matthias Kupperschmidt skal informere kontraktsparten om innen rimelig tid.</p>
          <p>Matthias Kupperschmidt forbeholder seg også retten til å endre eller supplere disse GTV, spesielt i tilfelle nye tjenester introduseres eller endringer kreves på grunn av lovbestemmelser. Matthias Kupperschmidt skal varsle kontraktsparten om endringer eller tillegg til GTV. Hvis kontraktsparten ikke er enig i endringene, kan han motsette seg dem innen to uker etter at de ble gjort kjent for ham skriftlig.</p>
          <p>I tilfelle kontraktsparten motsetter seg endringer i tjenestene, disse GTV eller de spesielle vilkårene for de individuelle produktene, har Matthias Kupperschmidt rett til å si opp kontraktene som ble inngått under disse GTV uten varsel. Hvis kontraktsparten ikke motsetter seg, anses hans samtykke som gitt ved utløpet av de ovennevnte periodene. Matthias Kupperschmidt skal påpeke lengden på perioden og betydningen av dens utløp i varslingen om endringen av GTV.</p>

          <p>Seksjon 15 Sluttbestemmelser</p>
          <p>Å sende varsler til Matthias Kupperschmidt via e-post oppfyller kravet til skriftlig form kun hvis det er uttrykkelig angitt i disse bruksvilkårene og hvis Matthias Kupperschmidt har gitt uttrykkelig samtykke til det på forhånd.</p>
          <p>En overføring av kontraktsrettigheter og forpliktelser fra kontraktsparten til en tredjepart er kun tillatt med forhåndsgodkjenning fra Matthias Kupperschmidt.</p>
          <p>Kontraktsparten har kun en rett til tilbakeholdelse i forhold til ubestridte eller rettslig fastsatte krav.</p>
          <p>Det finnes ingen tilleggsavtaler. Alle avtaler mellom partene må være skriftlige. Dette gjelder også for kansellering av skriftlig form krav.</p>
          <p>Kontraktsparten skal informere Matthias Kupperschmidt i god tid om pågående insolvensbehandlinger for å sikre oppfyllelsen av kontraktsforpliktelsene eller for å tillate en gjensidig oppsigelse av kontrakten.</p>
          <p>Skulle individuelle bestemmelser i Generelle Vilkår være ineffektive som helhet eller delvis eller hvis de inneholder en mangel, forblir effektiviteten av de gjenværende bestemmelsene eller deler av slike bestemmelser upåvirket av det. I et slikt tilfelle er partene forpliktet til å delta i opprettelsen av slike nye bestemmelser, ved hjelp av hvilke et kommersielt resultat oppnås med juridisk effekt som kommer nærmest den ineffektive eller ufullstendige bestemmelsen, samtidig som gjensidige interesser opprettholdes.</p>
          <p>Stedet for oppfyllelse for begge parter er forretningsstedet til Matthias Kupperschmidt.</p>
          <p>Loven i Danmark gjelder utelukkende med unntak av De Forente Nasjoners konvensjon om kontrakter for internasjonalt salg av varer (CISG) og henvisninger til utenlandsk lov.</p>
          <p>Hvis kunden er en handelsmann, juridisk enhet under offentlig rett eller et spesialfond under offentlig rett eller hvis han ikke har noen registrert kontor i Danmark, er den eksklusive jurisdiksjonen København, Danmark. Matthias Kupperschmidt har også rett til å inngi søksmål på forretningsstedet til kontraktsparten.</p>

          <p>Seksjon 13 Tvisteløsning</p>
          <p>Europakommisjonen har opprettet en plattform for online tvisteløsning. Den kan nås på: http://ec.europa.eu/consumers/odr/.</p>
          <p>Forbrukere kan bruke plattformen for tvisteløsning. Matthias Kupperschmidt er ikke villig eller forpliktet til å delta i tvisteløsningsprosesser før en Forbrukerklagenemnd med mindre det er en lovpålagt forpliktelse til å delta.</p>
          <p>København, 13. Juni 2024</p>

        </MainContent>
      </React.Fragment>

    </Layout>
  );
};
export default LegalNotice;